import { combineReducers } from 'redux';
import moment from 'moment';
import {  LOAD_VENDOR_MAINTENANCE_LIST, RESET_VENDOR_MAINTENANCE_LIST} from './constants';

const fast360MaintenanceState = {
    vendorMaintenanceList: []
}

const fast360MaintenanceReducer = (state = fast360MaintenanceState, action) => {
    switch (action.type) {
case LOAD_VENDOR_MAINTENANCE_LIST:
      return {
        ...state,
        vendorMaintenanceList: action.payload,
      };
    case RESET_VENDOR_MAINTENANCE_LIST:
      return {
        ...state,
        vendorMaintenanceList: [],
      };
      default:
        return { ...state };
    }
  };
  const reducers = combineReducers({
    fast360MaintenanceReducer: fast360MaintenanceReducer,
  });
  
  export default reducers;