import {  LOAD_VENDOR_MAINTENANCE_LIST, RESET_VENDOR_MAINTENANCE_LIST} from './constants';

const loadVendorMaintenanceList = data => {
    return {
      type: LOAD_VENDOR_MAINTENANCE_LIST,
      payload: data
    }
  }

  const resetVendorMaintenanceList = () => {
    return {
      type: RESET_VENDOR_MAINTENANCE_LIST,
    };
  };

  export default {
    loadVendorMaintenanceList,
    resetVendorMaintenanceList
  }