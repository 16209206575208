import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
  fast360MaintenanceOperations,
  fast360MaintenanceSelectors,
  fast360MaintenanceActions,
} from '../store/index';
import { authSelectors } from 'modules/auth/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faArrowRight, faFileUpload } from '@fortawesome/free-solid-svg-icons';
import {
  Col,
  FormGroup,
  Row,
  Label,
  Button,
  Input
} from 'reactstrap';
import AppTable from 'shared/components/table/AppTable';
const VENDOR_NAME_ID = 'VENDOR_NAME_ID';
const TAXID = 'TAXID';
const TELEPHONE_NUMBER = 'TELEPHONE_NUMBER';
const PRIMARY_CONTACT = 'PRIMARY_CONTACT';

const VendorMaintenance = ({
  vendorMaintenanceList,
  getVendorMaintenanceList,
  resetVendorMaintenanceList,
}) => {
  const options = [
    { value: VENDOR_NAME_ID, label: 'Vendor Name/ID' },
    { value: TAXID, label: 'Tax ID' },
    { value: TELEPHONE_NUMBER, label: 'Telephone Number' },
    { value: PRIMARY_CONTACT, label: 'Primary Contact' }
  ]
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [findVendorVal, setFindVendorVal] = useState('');
  const [tableVendorMaintenanceData, setTableVendorMaintenanceData] = useState([]);
  const [start, setStart] = useState(0);
  const recordsPerPage = 25;

  useEffect(() => {
    const params = {
      functionName: "queryVendors",
      vendorid: '%',
      level: "%",
      name: findVendorVal ? '%' + findVendorVal + '%' : '%%',
      parentid: "%",
      start: start.toString(),
      searchby: selectedOption.label
    }
    getVendorMaintenanceList(params);
  }, [start])

  useEffect(() => {
    if (vendorMaintenanceList && vendorMaintenanceList.length > 0) {
      setTableVendorMaintenanceData(vendorMaintenanceList);
    }
  }, [vendorMaintenanceList])

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  }

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setFindVendorVal(value);
  }

  const onFindHandler = () => {
    setStart(0);
    fetchVendorMaintenanceData(0, findVendorVal);
  }

  const fetchVendorMaintenanceData = (startIndex, findVendorVal) => {
    const params = {
      functionName: "queryVendors",
      vendorid: '%',
      level: "%",
      name: findVendorVal ? '%' + findVendorVal + '%' : '%%',
      parentid: "%",
      start: startIndex.toString(),
      searchby: selectedOption.label
    }
    getVendorMaintenanceList(params);
  }

  const handleNext = () => {
    setStart(start + recordsPerPage);
  }

  const handleBack = () => {
    if (start >= recordsPerPage) {
      setStart(start - recordsPerPage);
    }
  };

  const renderContactColumn = (item) => {
    let contactVal = '';
    if (item != null) {
      contactVal = item.primaryContact.people.last + ", " + item.primaryContact.people.first;
    }
    return contactVal;
  }

  const renderStreetColumn = (item) => {
    let streetVal = '';
    if ((item.address && item.address.street1 != null) && (item.address && item.address.street1 != "")) {
      streetVal = item.address.street1;
      if (item.address.street2 != null && item.address.street2 != "") {
        streetVal = ", " + item.address.street2;
      }
    }
    return streetVal;
  }

  const renderCityColumn = (item) => {
    let cityVal = '';
    if (item != null) {
      cityVal = item.address && item.address.zipCode && item.address.zipCode.city;
    }
    return cityVal;
  }

  const renderCountyColumn = (item) => {
    let countyVal = '';
    if (item != null) {
      countyVal = item.address && item.address.zipCode && item.address.zipCode.county;
    }
    return countyVal;
  }

  const renderStateColumn = (item) => {
    let stateVal = '';
    if (item != null) {
      stateVal = item.address && item.address.zipCode && item.address.zipCode.state;
    }
    return stateVal;
  }

  const renderZipColumn = (item) => {
    let zipVal = '';
    if (item != null) {
      zipVal = item.address && item.address.zipCode && item.address.zipCode.zip;
    }
    return zipVal;
  }

  const renderPhoneColumn = (item) => {
    let phoneVal = '';
    if (item.phone != null && item.phone != '' && item.phone != undefined) {
      phoneVal = `(${item.phone?.toString().substr(0, 3)})${item.phone?.toString().substr(3, 3)}-${item.phone?.toString().substr(6, 4)}`;
    } else {
      phoneVal = ''
    }
    return phoneVal;
  };

  const renderSubServiceColumn = (item) => {
    let subServiceListVal = "";
    for (const subService of item.serviceList) {
      subServiceListVal += ` ${subService.subService},`;
    }
    if (subServiceListVal !== "") {
      subServiceListVal = subServiceListVal.slice(0, -1);
    }
    return subServiceListVal;
  };

  const renderFeeCountColumn = (item) => {
    let feeCountVal = '';
    if (item.feeCount == 0) {
      feeCountVal = "";
    } else {
      feeCountVal = "$";
    }
    return feeCountVal;
  }

  const renderLevelColumn = (item) => {
    let coulumnVal = Number(item.level);
    if (item.hasChildren) {
      return (
        <div>
          {coulumnVal} {'  '}
          <FontAwesomeIcon icon={faArrowRight} style={{ color: '#0073e6' }} />
        </div>
      );
    } else {
      const className = 'active-green-circle';
      return (
        <div>
          {coulumnVal} {'  '}
          <FontAwesomeIcon className={className} icon={faCircle} />
        </div>
      );
    }
  };

  const renderActiveColumn = (item) => {
    let activeVal = '';
    if (item != null) {
      if (item.active == 1) {
        activeVal = "Yes";
      } else {
        activeVal = "No";
      }
    } else {
      activeVal = "No";
    }
    return activeVal;
  }

  const renderContractedColumn = (item) => {
    let contractedVal = '';
    if (item != null) {
      if (item.contracted == 1) {
        contractedVal = "Yes";
      } else {
        contractedVal = "No";
      }
    } else {
      contractedVal = "No";
    }
    return contractedVal;
  }

  const renderFilesColumn = (item) => {
    if (item != null) {
      return <span
        style={{
          marginLeft: '12px',
          cursor: 'pointer'
        }}
      >
        <FontAwesomeIcon
          icon={faFileUpload}
          onClick={() => { }} />
      </span>
    }
  }

  const getVendorMaintenanceLandingTable = () => {
    return (
      <div style={{ marginTop: "20px" }}>
        <AppTable
          modalHeight={400}
          columns={[
            {
              header: 'ID',
              field: 'id',
            },
            {
              header: '',
              field: 'feeCount',
              render: item => renderFeeCountColumn(item),
            },
            {
              header: 'Vendor/Location Name',
              field: 'name',
            },
            {
              header: 'DBA',
              field: 'dbaName',
            },
            {
              header: 'Vendor Type',
              field: 'mainService',
            },
            {
              header: 'Contact',
              field: 'primaryContact',
              render: item => renderContactColumn(item),
            },
            {
              header: 'Street',
              field: 'address',
              render: item => renderStreetColumn(item),
            },
            {
              header: 'City',
              field: 'city',
              render: item => renderCityColumn(item),
            },
            {
              header: 'County',
              field: 'county',
              render: item => renderCountyColumn(item),
            },
            {
              header: 'State',
              field: 'state',
              render: item => renderStateColumn(item),
            },
            {
              header: 'Zipcode',
              field: 'zip',
              render: item => renderZipColumn(item),
            },
            {
              header: 'Phone',
              field: 'phone',
              render: item => renderPhoneColumn(item)
            },
            {
              header: 'Email',
              field: 'email1',
            },
            {
              header: 'GP Code',
              field: 'gpCode',
            },
            {
              header: 'TIN',
              field: 'taxId',
            },
            {
              header: 'Level',
              field: 'level',
              render: item => renderLevelColumn(item)
            },
            {
              header: 'Active',
              field: 'active',
              render: item => renderActiveColumn(item)
            },
            {
              header: 'Contracted',
              field: 'contracted',
              render: item => renderContractedColumn(item)
            },
            {
              header: 'Sub-Service',
              field: 'serviceList',
              render: item => renderSubServiceColumn(item)
            },
            {
              header: 'Files',
              field: '',
              render: item => renderFilesColumn(item)
            }
          ]
          }
          data={tableVendorMaintenanceData || []}
          autoPagination={true}
          //sortAble={true}
          resizable={true}
          sortImmediately={false}
          rowDoubleClickHandler={() => { }}
          key="vendor-maintenance-landing-table"
        />
      </div>
    )
  }


  return (
    <div>
      <Row className="align-items-right">
        <Col>
          <FormGroup style={{ width: "300px", marginLeft: "auto", position: 'relative' }}>
            <Select
              value={selectedOption}
              name="vendormaintenanceFind"
              idKey="vendormaintenanceFind"
              onChange={handleSelectChange}
              options={options}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                  position: 'absolute'

                }),
              }}
            />
          </FormGroup>
        </Col>
        <Col lg={2}>
          <FormGroup>
            <Input
              type="text"
              name="name"
              id="vendorRecords"
              placeholder="Find Vendor Records"
              onChange={handleInputChange}
              value={findVendorVal}
            />
          </FormGroup>
        </Col>
        <Col lg="1">
          <Button
            style={{ width: '100%' }}
            type="button"
            onClick={onFindHandler}
          >
            FIND
          </Button>
        </Col>
      </Row>
      <Row className="align-items-right">
        <Col lg="2">
          <Button
            style={{ width: '100%' }}
            type="button"
            onClick={() => { }}
            disabled
          >
            Level Up
          </Button>
        </Col>
        <Col lg="2" style={{ marginTop: "6px" }}>
          <span>Path:</span>

        </Col>
        <Col lg="2" style={{ marginLeft: "auto" }}>
          <Button
            style={{ width: '100%' }}
            type="button"
            onClick={() => { }}
            disabled
          >
            NEW VENDOR LEVEL
          </Button>
        </Col>
        <Col lg="2">
          <Button
            style={{ width: '100%' }}
            type="button"
            onClick={() => { }}
            disabled
          >
            NEW VENDOR
          </Button>
        </Col>
        <Col lg="2">
          <Button
            style={{ width: '100%' }}
            type="button"
            onClick={() => { }}
            disabled
          >
            ADVANCED SEARCH
          </Button>
        </Col>
      </Row>
      <div>
        {getVendorMaintenanceLandingTable()}
      </div>
      <Row className="mt-3 justify-content-end" style={{ textAlign: "right" }} >
        <Col lg="1">
          <Button
            style={{ width: '100%' }}
            type="button"
            onClick={handleBack}
            title="previous 25 vendors"
            disabled={start === 0}
          >
            {'<'}
          </Button>
        </Col>
        <Col lg="1">
          <Button
            style={{ width: '100%' }}
            type='button'
            onClick={handleNext}
            title="next 25 vendors"
            disabled={tableVendorMaintenanceData.length < recordsPerPage}
          >
            {'>'}
          </Button>
        </Col>
      </Row>
    </div>
  );

};


const mapStateToProps = state => {
  const fast360MaintenacneInfo = fast360MaintenanceSelectors.getFast360Maintenance(state);
  const user = authSelectors.getUser(state);
  return {
    vendorMaintenanceList: fast360MaintenacneInfo.vendorMaintenanceList,
    userId: user.id,
  };
};

const mapDispatchToProps = dispatch => ({
  getVendorMaintenanceList: data => dispatch(fast360MaintenanceOperations.getVendorMaintenanceList(data)),
  resetVendorMaintenanceList: () =>
    dispatch(fast360MaintenanceActions.resetVendorMaintenanceList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VendorMaintenance);