import { ServiceExecutor } from 'core/services/serviceExecutor';
import { commonActions } from 'common/store';
import service from '../services/service';
import actions from './actions';


const getVendorMaintenanceList = data => {
    return async dispatch => {
      await ServiceExecutor.Builder(dispatch)
        .withServiceCall(() => service.getVendorMaintenanceList(data))
        .withResponseHandler(responseData => {
          dispatch(actions.loadVendorMaintenanceList(responseData));
          if (responseData === undefined || responseData.length === 0) {
            let msg = 'Zero results found';
            dispatch(commonActions.addAlert(new Date().getTime(), msg, 'danger'));
          }
        })
        .withFailureAlert('An error occurred while loading vendor Maintenance data')
        .build()
        .execute();
    };
  };

  export default {getVendorMaintenanceList };